<template>
  <perfect-scrollbar>
    <v-app
      class="p-0 m-0 vapp"
      style="height:100vh"
      transition="fade-transition"
    >
      <v-app-bar
        color="white"
        flat
        app
        height="80px"
        class="px-5"

      >
      <router-link :to="'/'" v-slot="{ href, navigate }">
        <a style="z-index:100" :href="href" @click="navigate">
          <v-img
            src="media/logos/logo_text.png"
            contain
            width="100px"
            height="64px"
          />
        </a>
      </router-link>
        <v-spacer />
        <KTTopbar></KTTopbar>
      </v-app-bar>
      <v-main class="mt-10">
        <v-container>
            <h1 class="text-primary">Privacy Policy</h1>
            <h2>Section I</h2>
            <h3>How Doctory Care Handels Security</h3>
            <p>Doctory Care follow generally accepted industry standards to protect
            the Personal Information submitted to us, both during transmission
            and once we receive it. These measures are in place to protect the
            security of your Personal Information and to ensure that your
            choices for its intended use are honored. We take strong precautions
            to protect your data from loss, misuse, unauthorized access or
            disclosure, alteration, or destruction. When you place orders or
            access your personal account information, you’re utilizing secure
            server software SSL, which encrypts your personal information before
            it’s sent over the Internet. SSL is one of the safest encryption
            technologies available. Sharing your personally identifiable
            information enables us to offer you a number of benefits. It will
            make it easier and more convenient for you to: • Request information
            specific to your interests; • Purchase and subscribe to the Doctory
            Care service online; • Receive personalized messages and special
            offers that are relevant to your interests; • Save time by storing
            your preferences; We carefully protect your data from loss, misuse,
            unauthorized access or disclosure, alteration, or destruction. Your
            Personal Information is not shared outside the company without your
            permission. Inside the company, data is stored in
            password-controlled servers with limited access. Your Personal
            Information may be stored and processed in different sites or
            locations where Doctory Care or its subsidiaries is located. You
            also have a significant role in protecting your information. Other
            than Doctory Care personnel that provide you with our services, no
            one can see or edit your personal information without knowing your
            user name and password, so do not share these with others.</p>
            <h3>How your medical information will be used and disclosed</h3>
            <p>As described in the Doctory Care Service Agreement and Terms of Use,
            we will use your medical information in order to enable us to
            provide you the Doctory Care services you subscribe for. For
            example, your medical information may be used by the healthcare
            professional at our Medical Call center that will monitor you when
            you call us; or in order to assist you in facilitating emergency
            response to your medical conditions.</p>
            <h2>Section II</h2>
            <h3>Information Collected About you</h3>
            <p>General Doctory Care believes strongly in protecting the privacy of
            the personally identifiable information you share with us. We also
            believe it is important to inform you about how we may use your
            personal data, and provide you choices about how those data will be
            used. Therefore, we recommend to you to read this Privacy Policy
            carefully. Doctory Care collects two kinds of information: (1)
            Personally identifiable medical information (“Personal Information”)
            voluntarily provided by visitors or subscribers to our services
            through optional registration or other methods and (2)
            non-personally identifiable information routinely gathered as
            visitors navigate through our Website. Personal Information You are
            free to visit and use our Website without providing any Personal
            Information. However, Doctory Care offers services on our Website
            that require registration and submission of Personal Information. In
            order to register or otherwise take advantage of the products and
            personalized services that we offer on our Website, you will have to
            provide Personal Information. The Personal Information we might
            request from you may include your name, address, email address,
            phone number and, if you are making a purchase of products or
            services, personal medical data and your billing / debit / credit
            card information. By way of example only, some situations in which
            we may request Personal Information include: when you register to
            become a subscriber to the Doctory Care service(s); when you
            register to receive informational Newsletter(s); when you
            participate in a survey on our Website; or to when you wish to
            participate in one of our interactive forums, including chat rooms
            or posting boards. The ways in which we collect Personal Information
            are described in more details below. Emails & Newsletters You may
            receive email subscriptions and newsletters from Doctory Care. A
            member of the Doctory Care team may contact members via email,
            telephone or postal mail for potential offerings, interviews,
            surveys or feature stories. Your cooperation with or participation
            in such activity is strictly voluntary and you are free to refuse to
            participate. Non-personally Identifiable Information You may use our
            Website without registering or submitting any Personal Information.
            In that case, the only information we will collect from you is
            general, aggregated, demographic, or other non-personal information.
            We may use a third-party tracking service that uses cookies and log
            files to track non-personal information about visitors to our
            Website in the aggregate. We may also share non-personal information
            with third parties such as advertisers and partners. This
            non-personal, aggregate data is used to analyze trends, to track
            user movement around the Website, and to gather demographic
            information about how members, subscribers, and/or visitors use our
            Website. Cookies A cookie is a small text file stored on a user’s
            computer for record-keeping purposes. We may use cookies on our
            Website to determine the number of unique visitors, to personalize
            your online experience, to identify returning visitors, and to
            provide you with messages of interest to you.Your browser may be set
            to reject all cookies. Most browsers offer instructions on how to
            reset the browser to reject cookies in the “Help” section of the
            toolbar. Log Files As is true of most websites, we may gather
            certain information automatically and store it in log files. This
            information includes internet protocol (IP) addresses, browser type,
            referring/exit pages, operating system, date/time stamp, and
            clickstream data. We use this information (which does not identify
            individual users) to analyze trends, to administer the Website, and
            to identify and solve hardware and software problems as they arise.
            We may link this automatically-collected data to your Personal
            Information for the purpose of determining your interests and to
            offer you services that we believe may be of interest to you.</p>
            <h2>Section III</h2>
            <h3>Contact Us with privacy-related questions</h3>
            <p>If for some reason you believe Doctory Care has not adhered to the
            principles of this Privacy Policy, or if you have a complaint or
            problem, you may email us at info@doctory.care and we will forward
            your complaint to the appropriate internal Doctory Care department
            for a response or resolution. We try to answer every email in a
            timely manner but are not always able to do so. BY USING OUR
            WEBSITE, YOU SIGNIFY YOUR ACCEPTANCE OF THIS PRIVACY POLICY. IF YOU
            DO NOT AGREE TO THIS PRIVACY POLICY, YOU SHOULD NOT USE OUR WEBSITE.
            YOUR CONTINUED USE OF OUR WEBSITE FOLLOWING THE POSTING OF CHANGES
            TO THIS PRIVACY POLICY WILL MEAN THAT YOU ACCEPT THOSE CHANGES.</p>
        </v-container>
      </v-main>
    </v-app>
  </perfect-scrollbar>
</template>

<script>
export default {
  name: "policy",
  components: {
    KTTopbar: () => import("@/view/layout/header/Topbar"),
  },
  data() {
    return {};
  },
  beforeMount() {},
  mounted() {
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
